import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Row, Col } from "antd";
import HoloCal from "../components/HoloCal"
import Events from "../components/Events"

class CalendarPage extends React.Component {
    render() {
      return (
        <Layout>
          <div className="index-container">
            <Helmet title={config.siteTitle} />
            <SEO />
            <Events />
            <hr className="zigzag" />
            <HoloCal />
          </div>
        </Layout>
      );
    }
  }

export default CalendarPage;
